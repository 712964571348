import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import "../style.css";

// markup
const Privacy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
      </Helmet>
      <main className="text-gray-700">
        <div className="py-8 mx-auto prose">
          <div className="sticky top-0">
            <Link className="block p-4 bg-gray-200" to="/">
              return to VicPeterson.com
            </Link>
          </div>
          <hr />
          <h1>Privacy Policy</h1>
          <p>
            THIS WEBSITE IS OFFERED TO YOU, THE USER, CONDITIONED ON YOUR
            ACCEPTANCE, WITHOUT MODIFICATION, OF THE FOLLOWING TERMS, CONDITIONS
            AND NOTIFICATIONS. YOUR USE OF THIS WEBSITE CONSTITUTES YOUR EXPRESS
            AGREEMENT TO ALL SUCH TERMS, CONDITIONS AND NOTIFICATIONS. ANY OF
            OUR RIGHTS NOT EXPRESSLY GRANTED HEREIN ARE SPECIFICALLY RESERVED.
          </p>
          <p>
            This document contains the privacy practices for&nbsp;
            <strong>
              <u>VicPeterson.com</u>
            </strong>
            &nbsp;website (the "<u>Site</u>"). This Site is operated by
            VicPeterson.com. The purpose of this privacy policy is to inform you
            about the information that is collected about you from your viewing
            or use of the Site, how VicPeterson.com may use and disclose the
            information that is collected, how information can be corrected or
            changed, and your obligations regarding information about others you
            may encounter in using the Site. Please note that this privacy
            policy only affects information gathered from you online through
            this Site. If VicPeterson.com already includes your information in
            its databases, or if VicPeterson.com adds your information to its
            databases through its other activities, such information and
            VicPeterson.com's use thereof will not be affected by this policy.
          </p>

          <h2>LIMITATION ON LIABILITY</h2>

          <p>
            BY USING THIS SITE, YOU ACKNOWLEDGE THAT WE SPECIFICALLY DISCLAIM
            ANY LIABILITY (WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY, OR
            OTHERWISE) FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
            PUNITIVE OR SPECIAL DAMAGES ARISING OUT OF, OR IN ANY WAY CONNECTED
            WITH, YOUR ACCESS TO OR USE OF THIS SITE (EVEN IF WE HAVE BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), INCLUDING, BUT NOT
            LIMITED TO, LIABILITY ASSOCIATED WITH ANY VIRUSES WHICH MAY INFECT A
            USER'S COMPUTER EQUIPMENT.
          </p>

          <p>
            ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED OR PROVIDED THROUGH
            THE USE OF THIS SITE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU
            WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM,
            OR LOSS OF DATA, THAT RESULTS FROM THE USE, DOWNLOAD OR PROVISION OF
            ANY SUCH MATERIAL.
          </p>

          <p>
            Except to the extent required by applicable law, we will not be
            liable for the unavailability or failure to operate all or any part
            of the service or system on which this Site is accessed, viewed or
            used.
          </p>

          <h2>Limitation on Your Usage</h2>

          <p>
            You may not modify, copy, distribute, license, transmit, reproduce,
            disseminate, display, transfer or sell, or offer for sale, any
            information, material, product, or service viewed on or obtained
            from this Site without express permission.
          </p>

          <h2>Information Collected and Use and Sharing of that Information</h2>

          <p>
            From time to time, including when you register to use this Site, we
            may ask you to provide certain information by which you may be
            identified, such as your name, title, address, organization name,
            telephone number, e-mail address, and similar information by which
            or with which you might be identified. This information will be
            referred to in this document as "<u>Personal Information</u>." You
            may decline to provide this Personal Information but you may thereby
            be unable to register or participate in activities or opportunities
            offered on the Site.
          </p>

          <p>
            VicPeterson.com may also use and disclose to others information
            automatically collected about you and your use of the Site,
            including information collected by third parties using tracking
            technologies on our Site, such as the type of browser you use, the
            files you request, the URL and geographic location from which you
            request information, the URL that you came from and go to, and
            similar information, and information compiled in the form of
            statistical, demographic, or aggregated information about visitors
            generally to the Site. This information is referred to in this
            document as "<u>Usage Information</u>." The methods that may be used
            on the Site to collect Usage Information include web log files and
            tracking technologies such as cookies, web beacons (also known as
            “tracking pixels”), embedded scripts, and location-identifying
            technologies:
          </p>

          <h3>Web log files</h3>

          <p>
            Like most website administrators, we use log files that record
            information such as Internet protocol (IP) addresses, browser type,
            Internet service provider (ISP), referring/exit pages, platform
            type, date/time stamp, and page navigation. We gather this
            information to track website visitor movement in the aggregate, and
            to gather broad demographic information for aggregate use. Our goal
            in gathering this information is to learn how our customers and the
            public are using our Site and services. IP addresses that are
            recorded by our log files are not linked to personally identifiable
            information.
          </p>

          <h3>"Cookies"</h3>

          <p>
            Our Site may use cookies.{" "}
            <a
              href="http://en.wikipedia.org/wiki/HTTP_cookie"
              target="_blank"
              rel="noreferrer"
            >
              Cookies are pieces of information that some websites transfer to
              the computer that is browsing that website and are used for
              record-keeping purposes at many websites.
            </a>{" "}
            VicPeterson.com uses "session cookies" while a visitor is using the
            message board on this Site. We use "session cookies" so that we can
            properly verify a user's identity as they move from one page to the
            next.
          </p>

          <p>
            Your browser is probably set to accept cookies. However, if you
            would prefer not to receive cookies, you can alter the configuration
            of your browser to refuse cookies. If you choose to have your
            browser refuse cookies, it is possible that some areas of our Site
            will not function properly when you view them.
          </p>

          <h3>Web Beacons (“Tracking Pixels”)</h3>

          <p>
            Web beacons are small graphic images, also known as "Internet tags"
            or "clear gifs," embedded in web pages and e-mail messages. Web
            beacons may be used to count the number of visitors to the Site, to
            monitor how users navigate the Site, and to count content views.
          </p>

          <h3>Embedded Scripts</h3>

          <p>
            An embedded script is programming code designed to collect
            information about your interactions with the Site. It is temporarily
            downloaded onto your device from our web server or a third party
            with whom we work, is active only while you are connected to the
            Site, and deleted or deactivated thereafter.
          </p>

          <h3>Location-identifying Technologies</h3>

          <p>
            Location-aware technologies may locate (sometimes precisely) you for
            purposes such as verifying your location and delivering or
            restricting relevant content based on your location.
          </p>

          <h2>Use and Sharing of Information</h2>

          <p>
            VicPeterson.com may use and disclose your information, including
            Personal Information and the information it automatically collects,
            in ways consistent with our statements under this Privacy Policy or
            as permitted as applicable law, including as follows:
          </p>

          <p>
            <u>
              For the purpose for which it was provided or with your consent.
            </u>
            &nbsp;If you give us information for a specific purpose, we will use
            it and may share it with our service providers for that purpose. We
            may also use and share your information with your consent or at your
            direction.
          </p>

          <p>
            <u>To manage and contact you about this Site.</u>
            &nbsp;VicPeterson.com may use your information to manage our Site
            and to contact you from time to time to provide you with information
            about VicPeterson.com or request your input about VicPeterson.com or
            this Site and ways in which we might improve. We may share
            information about you with our service providers for this purpose.
          </p>

          <p>
            <u>
              To inform you about VicPeterson.com’s information, developments,
              events, services, opportunities and fundraising efforts.
            </u>
            &nbsp;VicPeterson.com may contact you and send you information about
            its activities, products, services, events and fundraising efforts
            in which VicPeterson.com hopes you might be interested. We may share
            information about you with our service providers for this purpose.
          </p>

          <p>
            <u>For analytics purposes.</u>&nbsp;VicPeterson.com may use and
            share information about your with our service providers and other
            third parties, including publishers, to monitor and analyze trends,
            usage, and activities, for example in connection with the promotion
            of a new book, movie, or TV production.
          </p>

          <p>
            <u>Necessary disclosure of this Personal Information.</u>
            &nbsp;VicPeterson.com may disclose information, including Personal
            Information, to third parties as required by law, as we deem
            necessary or appropriate (such as pursuant to subpoena or court
            order). VicPeterson.com may need to disclose Personal Information
            when required by law wherein we have a good-faith belief that such
            action is necessary or reasonable to comply with a current judicial
            proceeding, a court order or legal process served on our
            organization. We may also disclose Personal Information to third
            parties in our discretion if we determine such disclosure is
            advisable to help avoid harm to persons or property. We may disclose
            your information to third parties to protect the rights, property,
            life, health, security and safety of us, the Site or any third
            party.
          </p>

          <p>
            <u>During a merger or sale.</u>&nbsp;VicPeterson.com may use and
            share information about you in connection with, or during
            negotiations of, any proposed or actual merger, purchase, sale or
            any other type of acquisition or business combination of all or any
            portion of our assets, or transfer of all or a portion of our
            business to another company.
          </p>

          <h2>Security of account information</h2>

          <p>
            VicPeterson.com makes significant efforts to protect from
            unauthorized access the data it collects. Nevertheless, transmission
            via the Internet is not completely secure and we cannot guarantee
            the security of your information collected through our Site.
          </p>

          <h2>Correction or Deletion of Your Information</h2>

          <p>
            <u>Requesting information.</u>&nbsp;You can request details of
            Personal Information compiled about you by contacting
            VicPeterson.com at info[at]vicpeterson.com.
          </p>

          <p>
            <u>Correcting Information.</u>&nbsp;You may correct information by
            informing VicPeterson.com at info[at]vicpeterson.com of the
            corrections that need to be made.
          </p>

          <p>
            <u>Deleting your Personal Information from our Database.</u>
            &nbsp;You may ask VicPeterson.com to delete your Personal
            Information from VicPeterson.com databases of this Site by
            contacting info[at]vicpeterson.com. If you delete your Personal
            Information from these databases, you may be unable to further
            benefit from use of certain portions of the Site or to use your
            password further.
          </p>

          <h2>Tell-A-Friend</h2>

          <p>
            If a user elects to use our referral service for informing a friend
            about our Site, we ask them for the friend's name and e-mail
            address. VicPeterson.com will automatically send the friend a
            one-time e-mail inviting them to visit the Site. VicPeterson.com
            stores this information for the sole purpose of sending this
            one-time e-mail.
          </p>

          <h2>Sweepstakes, Contests, and Promotions</h2>

          <p>
            We may offer sweepstakes, contests, surveys, and other promotions
            (each, a “Promotion”) jointly sponsored or offered by third parties
            that may require submitting Personal Information. If you voluntarily
            choose to enter a Promotion, your Personal Information may be
            disclosed to third parties for administrative purposes and as
            required by law (e.g., on a winners list). By entering, you agree to
            the official rules that govern that Promotion, and may, except where
            prohibited by applicable law, allow the sponsor and/or other parties
            to use your name, voice and/or likeness in advertising or marketing
            materials.
          </p>

          <h2>Children and Minors</h2>

          <p>
            VicPeterson.com encourages parents and guardians to monitor and
            participate in their children's online activities. No information
            should be submitted to this site by users under 13 years of age, and
            users under 13 years old are not allowed to make donations, register
            for newsletters, clubs or activities.
          </p>

          <p>
            Where appropriate, VicPeterson.com may ask you to indicate your age
            to verify compliance with these policies. VicPeterson.com does not
            knowingly collect information from users under 13 years of age or
            provide any personally identifying information from such users to
            any third party. We do not allow users known to be under 13 years of
            age to receive marketing communications from VicPeterson.com or from
            third parties over which we have control.
          </p>

          <p>
            No Personal Information about or from children (13 years old or
            under) should be provided on the Site. This Site is not designed for
            children. If you are a minor, please secure the permission and
            guidance of your parents or guardian before using this Site.
          </p>

          <h2>Links to Other Sites</h2>

          <p>
            We may have or add links to third party sites. Such linked sites are
            not under the control of VicPeterson.com and VicPeterson.com is not
            responsible for the accuracy, completeness or reliability of any
            information, data, opinions, advice or statements contained on any
            such linked sites. Access to any other Internet site linked to this
            website is at your own risk. VicPeterson.com has not reviewed all of
            the sites linked to this Site. VicPeterson.com provides these links
            merely as a convenience, and the inclusion of such links does not
            imply an endorsement by VicPeterson.com of such third party sites or
            any goods or services offered by such third parties.
          </p>

          <p>
            Please be aware that once you leave the Site and enter another
            website, you may be subject to different privacy practices and
            policies over which we have no control and for which VicPeterson.com
            is not responsible. Please use prudence in utilizing such links and
            in disclosing any personal information on those sites. Any
            information you share with these websites is subject to their
            policies and practices and is independent of VicPeterson.com.
          </p>

          <h2>Analytics and Advertising Tracking Technologies</h2>

          <p>
            We may use service providers for analytics services. These analytics
            services may use tracking technologies to help us analyze Site users
            and how they use the Site. Information generated by these services
            may be transmitted to and stored by these service providers on
            servers in the U.S. (or elsewhere) and these service providers may
            use this information for purposes such as evaluating your use of the
            Site, compiling statistic reports on the Site’s activity, and
            providing other services relating to Site activity and other
            internet usage.
          </p>

          <p>
            We may also engage and work with third parties to serve
            advertisements on the Site and/or on third party sites. These third
            parties may use tracking technologies to tailor ads to your
            interests based on your browsing of the Site and elsewhere on the
            internet, sometimes referred to as “interest-based advertising” and
            “online behavioral advertising (“Interest-based Advertising”), which
            may include sending you an ad on a third party site after you have
            left the Site (i.e., “retargeting”).
          </p>

          <p>
            You may choose whether to receive Interest-based Advertising by
            submitting opt-outs. Some of the advertisers and service providers
            that perform advertising-related services for us and our partners
            may participate in the Digital Advertising Alliance ("DAA")
            Self-Regulatory Program for Online Behavioral Advertising. To learn
            more about how you can exercise certain choices regarding
            Interest-based Advertising, visit{" "}
            <a
              href="http://www.aboutads.info/choices"
              target="_blank"
              rel="noreferrer"
            >
              http://www.aboutads.info/choices
            </a>
            , and{" "}
            <a
              href="http://www.aboutads.info/appchoices"
              target="_blank"
              rel="noreferrer"
            >
              http://www.aboutads.info/appchoices
            </a>{" "}
            for information on the DAA’s opt-out program for mobile apps. Some
            of these companies may also be members of the Network Advertising
            Initiative (“NAI”). To learn more about the NAI and your opt-out
            options for their members, see &nbsp;
            <a
              href="http://www.networkadvertising.org/choices/"
              target="_blank"
              rel="noreferrer"
            >
              http://www.networkadvertising.org/choices/
            </a>
            . Please be aware that, even if you are able to opt out of certain
            kinds of Interest-based Advertising, you may continue to receive
            other types of ads. Opting out only means that those selected
            members should no longer deliver certain Interest-based Advertising
            to you, but does not mean you will no longer receive any targeted
            content and/or ads (e.g., from other ad networks). We are not
            responsible for effectiveness of, or compliance with, any
            third-parties’ opt-out options or programs or the accuracy of their
            statements regarding their programs.
          </p>

          <h2>“Do Not Track”</h2>

          <p>
            Your browser settings may allow you to automatically transmit a “Do
            Not Track” signal to online services you visit. Note, however, there
            is no industry consensus as to what site and app operators should do
            with regard to these signals. Accordingly, we do not monitor or take
            action with respect to “Do Not Track” signals or other mechanisms.
            For more information on “Do Not Track,” visit &nbsp;
            <a
              href="http://www.allaboutdnt.com"
              target="_blank"
              rel="noreferrer"
            >
              http://www.allaboutdnt.com
            </a>
            .
          </p>

          <h2>Your California Privacy Rights</h2>

          <p>
            California’s “Shine the Light” law permits customers in California
            to request certain details about how certain types of their
            information are shared with third parties and, in some cases,
            affiliates, for those third parties’ and affiliates’ own direct
            marketing purposes. Under the law, a business should either provide
            California customers certain information upon request or permit
            California customers to opt in to, or opt out of, this type of
            sharing.
          </p>

          <p>
            We may share personal information as defined by California’s “Shine
            the Light” law with third parties and/or affiliates for such third
            parties’ and affiliates’ own direct marketing purposes. If you are a
            California resident and wish to obtain information about our
            compliance with this law, please contact info[at]vicpeterson.com.
          </p>

          <h2>Ownership of Information</h2>

          <p>
            All opinions, ideas, suggestions, and other feedback submitted to
            this Site may be used by VicPeterson.com without any restriction and
            free of charge, and nothing contained in this policy shall limit or
            otherwise affect this.
          </p>

          <h2>Changes to this Privacy Policy</h2>

          <p>
            We may change this privacy policy at any time and at our discretion
            without notice to any party. Any such change is posted on this Site.
            If you wish to monitor changes in this Site, please check this Site
            periodically for updates.
          </p>

          <h2>Applicable Law and Venue</h2>

          <p>
            Regardless of where you live, work or access this Site, this policy
            and disclaimer agreement, or any information on this Site, and your
            relationship with us, will be governed by the laws of the State of
            Kansas. By using this Site, you consent to the exclusive
            jurisdiction of courts in the State of Kansas, Douglas County in any
            matter related to the use or contents of this Site.
          </p>
        </div>
      </main>
      <footer className="p-4 text-sm text-center text-gray-600">
        ©2021 Vic Peterson
      </footer>
    </>
  );
};

export default Privacy;
